import * as React from 'react';
import { graphql } from 'gatsby';
import { getNameAndWeight } from 'utils';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { DiscountTag } from 'components/DiscountTag';
import { CartCounter } from 'components/CartCounter';
import s from './product.module.css';

type Props = {
  readonly data: GatsbyTypes.ProductPageQuery;
};

const ProductPage = ({ data }: Props) => {
  const p = data.api.product;
  if (!p) {
    return null;
  }

  const [name, weight] = getNameAndWeight(p.name, p.unit, p.weight);

  const breadcrumbs = [];
  if (p.categories.length > 0) {
    breadcrumbs.push({
      label: p.categories[0].name,
      url: p.categories[0].pageUrl,
    });
  }

  return (
    <Layout>
      <SEO title={name} />
      <div className="text-black pc-row">
        <Breadcrumbs items={breadcrumbs} />
        <div className="flex mb-12">
          <div className={s.imageWrapper}>
            <img src={p.imageUrl} srcSet={`${p.retinaImageUrl} 2x`} alt={name} data-sizes="auto" className="lazyload" />
            <DiscountTag price={p.price} oldPrice={p.oldPrice} className={s.discountTag} />
          </div>
          <div className="flex flex-col w-full">
            <h1 className="text-5xl font-bold leading-none mb-6">{name}</h1>
            {weight && <div className="text-gray-48 text-2xl leading-none mb-6">{weight}</div>}
            <CartCounter data={p} type="product" className="rounded-lg border border-gray-90 p-6 mt-auto" />
          </div>
        </div>
        {p.composition && (
          <div className="leading-5 mb-8">
            <div className="text-gray-64 font-bold mb-2">Состав:</div>
            {p.composition}
          </div>
        )}
        {p.vendorCountry && (
          <div className="leading-5 mb-8">
            <div className="text-gray-64 font-bold mb-2">Страна производитель:</div>
            {p.vendorCountry}
          </div>
        )}
        {p.expiresIn && (
          <div className="leading-5 mb-8">
            <div className="text-gray-64 font-bold mb-2">Срок годности:</div>
            {p.expiresIn}
          </div>
        )}
        {p.storageRequirements && (
          <div className="leading-5 mb-8">
            <div className="text-gray-64 font-bold mb-2">Условия хранения:</div>
            {p.storageRequirements}
          </div>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductPage($rowId: Int!) {
    api {
      product(rowId: $rowId) {
        name
        unit
        weight
        categories {
          name
          pageUrl
        }
        imageUrl: mainImageUrl(size: 584)
        retinaImageUrl: mainImageUrl(size: 1168)
        price
        oldPrice
        composition
        vendorCountry
        expiresIn
        storageRequirements
        ...CartCounter_data
      }
    }
  }
`;

export default ProductPage;
